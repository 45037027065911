// DetectLanguage.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DetectLanguage = ({ onLanguageReady }) => {
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    const lang = location.pathname.split('/')[1];
    if (lang !== i18n.language) {
      i18n.changeLanguage(lang).then(onLanguageReady); // Call onLanguageReady after change
    } else {
      onLanguageReady(); // Immediate call if no change needed
    }
  }, [location, i18n, onLanguageReady]);

  return null;
};

export default DetectLanguage;
