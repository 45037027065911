import React, { lazy } from 'react';

const Home                 = lazy(() => import('./pages/Home'                ));
const AboutUs              = lazy(() => import('./pages/AboutUs'             ));
const Services             = lazy(() => import('./pages/Services'            ));
const Resources            = lazy(() => import('./pages/Resources'           ));
const ContactUs            = lazy(() => import('./pages/ContactUs'           ));
const Asylum               = lazy(() => import('./pages/Asylum'              ));
const AsylumAffirmative    = lazy(() => import('./pages/AsylumAffirmative'   ));
const AsylumDefensive      = lazy(() => import('./pages/AsylumDefensive'     ));
const AsylumDetained       = lazy(() => import('./pages/AsylumDetained'      ));
const AsylumCFI            = lazy(() => import('./pages/AsylumCFI'           ));
const TranslationTemplates = lazy(() => import('./pages/TranslationTemplates'));

const routes = [
    { path: '/'                        , element: <Home                 /> },
    { path: '/en'                      , element: <Home                 /> },
    { path: '/en/about'                , element: <AboutUs              /> },
    { path: '/en/services'             , element: <Services             /> },
    { path: '/en/resources'            , element: <Resources            /> },
    { path: '/en/contact'              , element: <ContactUs            /> },
    { path: '/en/asylum'               , element: <Asylum               /> },
    { path: '/en/asylumAffirmative'    , element: <AsylumAffirmative    /> },
    { path: '/en/asylumDefensive'      , element: <AsylumDefensive      /> },
    { path: '/en/asylumDetained'       , element: <AsylumDetained       /> },
    { path: '/en/asylumCFI'            , element: <AsylumCFI            /> },
    { path: '/en/translationTemplates' , element: <TranslationTemplates /> },
    { path: '/ru'                      , element: <Home                 /> },
    { path: '/ru/about'                , element: <AboutUs              /> },
    { path: '/ru/services'             , element: <Services             /> },
    { path: '/ru/resources'            , element: <Resources            /> },
    { path: '/ru/contact'              , element: <ContactUs            /> },
    { path: '/ru/asylum'               , element: <Asylum               /> },
    { path: '/ru/asylumAffirmative'    , element: <AsylumAffirmative    /> },
    { path: '/ru/asylumDefensive'      , element: <AsylumDefensive      /> },
    { path: '/ru/asylumDetained'       , element: <AsylumDetained       /> },
    { path: '/ru/asylumCFI'            , element: <AsylumCFI            /> },
    { path: '/ru/translationTemplates' , element: <TranslationTemplates /> }
];

export default routes;

// Export a plain object containing paths
export const routePaths = routes.map(route => route.path);
