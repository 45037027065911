// App.js
import React, { Suspense, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import DetectLanguage from './components/DetectLanguage';
import './App.css';
import './i18n';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import routes from './routes';

Amplify.configure(awsExports);

const App = () => {
  //console.log('Registered Routes:', routes); // Log the registered routes
  const [languageReady, setLanguageReady] = useState(false);

  return (
    <Router>
      <DetectLanguage onLanguageReady={() => setLanguageReady(true)} />
      {languageReady && (
        <div className="main-content">
          <NavBar />
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              {routes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Routes>
          </Suspense>
        </div>
      )}
    </Router>
  );
}

export default App;

// npm run build
// To deploy, from command-line "As Administrator"
// aws s3 cp ./build s3://vstnk --recursive --metadata-directive REPLACE --cache-control "no-cache"
// aws s3 cp ./my-website s3://my-bucket-name --recursive --metadata-directive REPLACE --cache-control "no-cache, no-store, must-revalidate"

// TODO
// - Move the questionnaire site into this site so that everything is under the same name and same https for security.
