import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import logoImage from './VESTNIK Logo Inverted.webp';
import './NavBar.css';

const NavBar = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isMenuActive, setIsMenuActive] = useState(false);

  const closeMenu = () => {
    setIsMenuActive(false);
  };

  const lang = pathname.split('/')[1] || 'en';

  return (
    <header className={`navbar-header ${isMenuActive ? 'menu-active' : ''}`}>
      <nav className="navbar">
        <div className="navbar-brand">
          <button className="hamburger-menu" onClick={() => setIsMenuActive(!isMenuActive)}>
            ☰
          </button>
          <img src={logoImage} alt="Logo" className="navbar-logo" />
          <Link to={`/${lang}`} className="brand-name" onClick={closeMenu}>{t('vestnik')}</Link>
          <div className={`nav-links ${isMenuActive ? 'show' : ''}`}>
            <Link to={`/${lang}`          } className="nav-link" onClick={closeMenu}>{t('home'         )}</Link>
            <Link to={`/${lang}/about`    } className="nav-link" onClick={closeMenu}>{t('about'        )}</Link>
            <Link to={`/${lang}/services` } className="nav-link" onClick={closeMenu}>{t('services'     )}</Link>
            <Link to={`/${lang}/resources`} className="nav-link" onClick={closeMenu}>{t('resources'    )}</Link>
            <Link to={`/${lang}/contact`  } className="nav-link" onClick={closeMenu}>{t('contact'      )}</Link>
            <a href="https://questionnaire.vstnk.org" rel="noopener noreferrer" 
              target="_blank"               className="nav-link" onClick={closeMenu}>{t('questionnaire')}
            </a>
          </div>
        </div>
        <LanguageSwitcher />
      </nav>
    </header>
  );
};

export default NavBar;
