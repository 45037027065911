import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const changeLanguage = (lng) => {
    const pathSegments = location.pathname.split('/');
    pathSegments[1] = lng; // Update the language segment
    const newPath = pathSegments.join('/');
    i18n.changeLanguage(lng);
    navigate(newPath);
  };

  return (
    <div className="language-switcher">
      <div className="language-option" onClick={() => changeLanguage('en')}>
        English
      </div>
      <div className="language-option" onClick={() => changeLanguage('ru')}>
        Русский
      </div>
    </div>
  );
};

export default LanguageSwitcher;
